<template>
  <div class="w-100 d-flex justify-content-center">
    <CButton :disabled="isLoading" :class="Class" :color="Color">
      <span v-if="isLoading">
        <div class="spinner-border spinner-border-sm"></div>
        Please wait
      </span>
      <span v-else>{{ Text }}</span>
    </CButton>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'LoadingButton',
  components: {},
  props: ['Class', 'Text', 'Color', 'isLoading'],
}
</script>
