<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle
      @click.prevent="showDropDown"
      placement="bottom-end"
      class="py-0"
      :caret="false"
    >
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem @click="showProfile = true" role="button">
        <CIcon icon="cil-user" /> Profile
      </CDropdownItem>
      <CDropdownItem role="button" @click="logout">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>

    <CModal
      :visible="showProfile"
      @close="
        () => {
          showProfile = false
        }
      "
    >
      <CModalHeader>
        <CModalTitle>Profile</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div v-if="user != null">
          <div>Name:</div>
          <div>{{ user.firstname }} {{ user.lastname }}</div>

          <div class="mt-2">Email:</div>
          <div>{{ user.email }}</div>

          <div class="mt-2">Phone:</div>
          <div>{{ user.phone }}</div>

          <div class="mt-2">Role:</div>
          <div>{{ user.user_type }}</div>

          <div class="mt-2">Cost center:</div>
          <div v-if="user.cost_center">{{ user.cost_center.name }}</div>

          <div class="mt-2">Department:</div>
          <div v-if="user.department">{{ user.department.name }}</div>
        </div>
      </CModalBody>
    </CModal>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/person.png'
export default {
  name: 'AppHeaderDropdownAccnt',
  data() {
    return {
      user: null,
      avatar: avatar,
      itemsCount: 42,
      showProfile: false,
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user'))
  },
  methods: {
    showDropDown() {},
    logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      this.$router.replace('/logout')
    },
  },
}
</script>
