import iziToast from './IziToast'
import moment from 'moment/moment'

const commons = {
  mixins: [iziToast],
  data() {
    return {
      // baseUrl: 'https://markh-api.msc-test.app',
      baseUrl: 'https://api.markhpay.com',
      // baseUrl: 'http://127.0.0.1:7000',
      // baseUrl: 'http://127.0.0.1:4000',
      showPage: false,
      openPage: '',
    }
  },
  methods: {
    pad_with_zeroes(number, len) {
      let my_string = '' + number
      while (my_string.length < len) {
        my_string = '0' + my_string
      }

      return my_string
    },
    intToString(num) {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(num)
    },
    formatDate: function (value, format) {
      if (!value) return ''
      if (!format) format = 'D/MMM/yyyy'
      return moment(value).format(format)
    },
    formatMoney(n, currency) {
      return new Intl.NumberFormat('ug', {
        style: 'currency',
        currency: currency,
      }).format(n)
    },
    formatMoneyPlain(num, n, x) {
      const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
      return num.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
    },
    validateForm(formId, onValid) {
      const form = document.getElementById(formId)
      if (!form.checkValidity()) {
        form.classList.add('was-validated')
      } else {
        onValid()
      }
    },
    displayAxiosErrorMessage(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        this.showErrorMessage(error.response.data.message)
      } else {
        this.showErrorMessage('Internal server error')
      }
    },
    debounce(func, wait, immediate) {
      var timeout

      return function executedFunction() {
        var context = this
        var args = arguments

        var later = function () {
          timeout = null
          if (!immediate) func.apply(context, args)
        }

        var callNow = immediate && !timeout

        clearTimeout(timeout)

        timeout = setTimeout(later, wait)

        if (callNow) func.apply(context, args)
      }
    },
  },
}

export default commons
