<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="5">
          <CCardGroup>
            <CCard class="p-4">
              <div class="text-center">
                <img :src="logo" height="50" width="130" />
              </div>
              <CCardBody>
                <CForm id="form-1" novalidate>
                  <h6 class="text-center">Reset otp</h6>
                  <p class="text-center text-medium-emphasis">
                    Please provide otp sent to your email.
                  </p>

                  <div class="d-flex justify-content-center mb-3">
                    <v-otp-input
                      ref="otpInput"
                      input-classes="otp-input"
                      separator="-"
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      :conditionalClass="['one', 'two', 'three', 'four']"
                      :placeholder="['', '', '', '']"
                      @on-complete="onComplete"
                    />
                  </div>
                  <CRow>
                    <CCol class="text-center" :xs="12">
                      <LoadingButton
                        ref="SaveBtn"
                        @click="gotoReset"
                        Class="w-50"
                        Color="primary"
                        Text="Reset"
                        :isLoading="isLoading"
                      />
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>

<script>
import axios from 'axios'
import iziToast from '../../mixin/IziToast'
import commons from '../../mixin/commons'
import logo from '@/assets/images/logo.png'
import LoadingButton from '@/components/LoadingButton'

export default {
  name: 'ForgotPassword',
  mixins: [iziToast, commons],
  components: {
    LoadingButton,
  },
  data() {
    return {
      code: '',
      toastMessage: '',
      showToast: false,
      logo: logo,
      isLoading: false,
    }
  },
  mounted() {
    this.showToast = false
    this.toastMessage = ''
  },
  methods: {
    onComplete(value) {
      this.code = value
    },
    gotoReset() {
      if (this.code.length != 6) {
        this.showErrorMessage('Please provide a valid code.')
      } else {
        this.isLoading = true
        axios
          .post(`${this.baseUrl}/api/user/verify-password-reset-code`, {
            email: localStorage.getItem('otp-email'),
            code: this.code,
          })
          .then((response) => {
            this.isLoading = false
            const data = response.data.data
            console.log(data)
            localStorage.setItem('otp-code', this.code)
            this.$router.push('/reset-password')
          })
          .catch((error) => {
            this.isLoading = false
            this.showErrorMessage(error.response.data.message)
          })
      }
    },
  },
}
</script>
