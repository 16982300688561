import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

import MenuIcon from 'vue-material-design-icons/Menu.vue'
import FilterVariantIcon from 'vue-material-design-icons/FilterVariant.vue'
import DownloadIcon from 'vue-material-design-icons/Download.vue'
import UpIcon from 'vue-material-design-icons/ArrowUp.vue'
import DownIcon from 'vue-material-design-icons/ArrowDown.vue'

import { plugin } from 'vue3-timeline'
import 'vue3-timeline/dist/style.css'

import VOtpInput from 'vue3-otp-input'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(plugin)
app.use(VueSweetalert2)
app.provide('icons', icons)
app.component('v-otp-input', VOtpInput)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('menu-icon', MenuIcon)
app.component('filter-icon', FilterVariantIcon)
app.component('download-icon', DownloadIcon)
app.component('up-arrow-icon', UpIcon)
app.component('down-arrow-icon', DownIcon)

app.mount('#app')
