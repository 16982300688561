<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="5">
          <CCardGroup>
            <CCard class="p-4">
              <div class="text-center">
                <img :src="logo" height="50" width="130" />
              </div>
              <CCardBody>
                <CForm id="form-1" novalidate>
                  <h5 class="text-center">Forgot Password</h5>
                  <p class="text-center text-medium-emphasis">
                    Please provide your email to recover password.
                  </p>
                  <CInputGroup class="mb-3">
                    <CFormInput
                      required
                      v-model="email"
                      placeholder="Email"
                      autocomplete="email"
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol class="text-center" :xs="12">
                      <LoadingButton
                        ref="SaveBtn"
                        @click="getOtp"
                        Class="w-50"
                        Color="primary"
                        Text="Continue"
                        :isLoading="isLoading"
                      />
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios'
import iziToast from '../../mixin/IziToast'
import commons from '../../mixin/commons'
import logo from '@/assets/images/logo.png'
import LoadingButton from '@/components/LoadingButton'

export default {
  name: 'ForgotPassword',
  mixins: [iziToast, commons],
  components: {
    LoadingButton,
  },
  data() {
    return {
      email: '',
      toastMessage: '',
      showToast: false,
      logo: logo,
      isLoading: false,
    }
  },
  mounted() {
    this.showToast = false
    this.toastMessage = ''
  },
  methods: {
    getOtp() {
      const form = document.getElementById('form-1')
      if (!form.checkValidity()) {
        form.classList.add('was-validated')
      } else {
        this.isLoading = true
        localStorage.removeItem('otp-email')
        axios
          .post(`${this.baseUrl}/api/user/password-reset-code`, {
            email: this.email,
          })
          .then((response) => {
            this.isLoading = false
            const data = response.data.data
            console.log(data)
            localStorage.setItem('otp-email', this.email)
            this.$router.push('/otp-verification')
          })
          .catch((error) => {
            this.isLoading = false
            this.showErrorMessage(error.response.data.message)
          })
      }
    },
  },
}
</script>
