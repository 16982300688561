<template>
  <div class="wrapper-2">
    <input
      v-model="searchQuery"
      @input="searchByPRN($event)"
      class="form-control w-100"
      placeholder="Search by PRN"
    />
    <div v-if="items.length > 0" class="search-overlay card p-3">
      <div
        v-for="(item, index) in items"
        :key="index"
        @click="gotoDetails(item)"
        class="row search-item"
      >
        <div class="col-md-9">
          <h6>{{ item.pnr }}</h6>
          <div>{{ item.user.firstname }} {{ item.user.lastname }}</div>
        </div>
        <div class="col-md-3">
          <button class="btn btn-primary hide-btn">view</button>
        </div>
        <div class="col-md-12">
          <hr v-if="index != items.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hide-btn {
  display: none;
}

.search-item:hover .hide-btn {
  display: block;
}
.wrapper-2 {
  position: relative;
  width: 500px;
}
.search-overlay {
  position: absolute;
  top: 5;
  width: 100%;
  z-index: 99999;
  background-color: #fff;
  margin-top: 10px;
}
</style>

<script>
import commons from '@/mixin/commons'
import axios from 'axios'

export default {
  name: 'auto-complete',
  mixins: [commons],
  data() {
    return {
      loading: false,
      searchQuery: '',
      items: [],
    }
  },
  methods: {
    gotoDetails(cashRequest) {
      this.items = []
      this.searchQuery = ''
      this.$router.push(`/cash-request-details/${cashRequest.id}`)
    },
    searchByPRN(event) {
      let prn = event.target.value

      console.log(prn)

      if (prn.length >= 3) {
        let url = this.baseUrl + `/api/cash-request/search-by-prn?prn=${prn}`
        this.loading = true
        axios
          .get(url, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
          })
          .then((res) => {
            this.loading = false
            this.items = res.data.data
          })
          .catch((error) => {
            console.log(error.response)
            this.loading = false
          })
      } else if (prn.length == 1) {
        this.items = []
      }
    },
  },
}
</script>
