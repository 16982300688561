<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="5">
          <CCardGroup>
            <CCard class="p-4">
              <div class="text-center">
                <img :src="logo" height="50" width="130" />
              </div>
              <CCardBody>
                <CForm id="form-1" novalidate>
                  <h5 class="text-center">Reset Password</h5>
                  <p class="text-center text-medium-emphasis">
                    Please enter new password.
                  </p>
                  <CInputGroup class="mb-3">
                    <CFormInput
                      required
                      type="password"
                      v-model="password"
                      placeholder="New password"
                    />
                  </CInputGroup>
                  <CInputGroup class="mb-3">
                    <CFormInput
                      required
                      type="password"
                      v-model="confirmPassword"
                      placeholder="Confirm password"
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol class="text-center" :xs="12">
                      <LoadingButton
                        ref="SaveBtn"
                        @click="changePassword"
                        Class="w-50"
                        Color="primary"
                        Text="Confirm"
                        :isLoading="isLoading"
                      />
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios'
import iziToast from '../../mixin/IziToast'
import commons from '../../mixin/commons'
import logo from '@/assets/images/logo.png'
import LoadingButton from '@/components/LoadingButton'

export default {
  name: 'ResetPassword',
  mixins: [iziToast, commons],
  components: {
    LoadingButton,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      toastMessage: '',
      showToast: false,
      logo: logo,
      isLoading: false,
    }
  },
  mounted() {
    this.showToast = false
    this.toastMessage = ''
  },
  methods: {
    changePassword() {
      const form = document.getElementById('form-1')
      if (!form.checkValidity()) {
        form.classList.add('was-validated')
      } else {
        if (this.password != this.confirmPassword) {
          this.showErrorMessage("Password don't match")
          return
        }

        this.isLoading = true
        axios
          .post(`${this.baseUrl}/api/user/change-password`, {
            email: localStorage.getItem('otp-email'),
            code: localStorage.getItem('otp-code'),
            password: this.password,
          })
          .then((response) => {
            this.isLoading = false
            const data = response.data.data
            console.log(data)
            this.showSuccessMessage('Password updated successfully')
            localStorage.removeItem('otp-code')
            localStorage.removeItem('otp-email')
            this.$router.push('/')
          })
          .catch((error) => {
            this.isLoading = false
            this.showErrorMessage(error.response.data.message)
          })
      }
    },
  },
}
</script>
