export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Departments',
    to: '/departments',
    icon: 'cil-building',
  },
  {
    component: 'CNavItem',
    name: 'Cost centers',
    to: '/cost-centers',
    icon: 'cib-at-and-t',
  },
  {
    component: 'CNavItem',
    name: 'Categories',
    to: '/categories',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Sub categories',
    to: '/sub-categories',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Pending Cash requests',
    to: '/cash-requests',
    icon: 'cil-dollar',
  },
  {
    component: 'CNavItem',
    name: 'All Cash requests',
    to: '/all-cash-requests',
    icon: 'cil-dollar',
  },
  {
    component: 'CNavItem',
    name: 'Activity Logs',
    to: '/activity-logs',
    icon: 'cil-dollar',
  },
]
