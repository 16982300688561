<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="5">
          <CCardGroup>
            <CCard class="p-4">
              <div class="text-center">
                <img :src="logo" height="50" width="130" />
              </div>
              <CCardBody>
                <CForm id="form-1" novalidate>
                  <h3 class="text-center">Login</h3>
                  <p class="text-center text-medium-emphasis">
                    Sign In to your account
                  </p>
                  <CInputGroup class="mb-3">
                    <CInputGroupText>
                      <CIcon icon="cil-user" />
                    </CInputGroupText>
                    <CFormInput
                      required
                      v-model="email"
                      placeholder="Username"
                      autocomplete="username"
                    />
                  </CInputGroup>
                  <CInputGroup class="mb-4">
                    <CInputGroupText>
                      <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                      required
                      v-model="password"
                      type="password"
                      placeholder="Password"
                      autocomplete="current-password"
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol class="text-center" :xs="12">
                      <LoadingButton
                        ref="SaveBtn"
                        @click="loginApp"
                        Class="w-50"
                        Color="primary"
                        Text="Login"
                        :isLoading="isLoading"
                      />
                    </CCol>
                    <CCol :xs="12" class="text-center">
                      <CButton
                        @click="gotoForgotPassword()"
                        color="link"
                        class="px-0"
                      >
                        Forgot password?
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <div class="mt-3" style="text-align: center">
            <a :href="baseUrl + '/download-apk'">Download mobile app</a>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios'
import iziToast from '../../mixin/IziToast'
import commons from '../../mixin/commons'
import logo from '@/assets/images/logo.png'
import LoadingButton from '@/components/LoadingButton'

export default {
  name: 'Login',
  mixins: [iziToast, commons],
  components: {
    LoadingButton,
  },
  data() {
    return {
      email: '',
      password: '',
      toastMessage: '',
      showToast: false,
      logo: logo,
      isLoading: false,
    }
  },
  mounted() {
    this.showToast = false
    this.toastMessage = ''
  },
  methods: {
    gotoForgotPassword() {
      this.$router.push('/forgot-password')
    },
    loginApp() {
      const form = document.getElementById('form-1')
      if (!form.checkValidity()) {
        form.classList.add('was-validated')
      } else {
        this.isLoading = true
        axios
          .post(`${this.baseUrl}/api/user/login`, {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            this.isLoading = false
            const data = response.data.data
            console.log(data)
            localStorage.setItem('accessToken', data.accessToken)
            localStorage.setItem('user', JSON.stringify(data.user))
            localStorage.setItem(
              'permissions',
              JSON.stringify(data.user.permissions.map((e) => e.permission)),
            )

            this.$router.push('/dashboard')
          })
          .catch((error) => {
            this.isLoading = false
            this.showErrorMessage(error.response.data.message)
          })
      }
    },
  },
}
</script>
